$font-regular: "StyreneALC-Regular";
$font-medium: "StyreneALC-Medium";
$font-bold: "StyreneALC-Bold";

$white: #fff;

$red-1: #EF3124;
$red-2: #FF4F42;

$black-1: #000;
$black-2: #24252E;

$grey-1: #505759;
$grey-2: #F1F2F3;
$grey-3: #DADADA;