@import "../../common/vars.scss";

.wrapper {
  padding: 0px 20px;
  font-family: $font-regular;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 92vh;
}

.image {
  margin-top: 30px;
}

.title {
  font-family: $font-medium;
  font-size: 24px;
  color: #ef3124;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 16px;
}

.description {
  font-family: $font-regular;
  font-size: 17px;
  color: #a7abac;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.descriptionOffset {
  @media (max-width: 410px) {
    br {
      display: block;
    }
  }
}

.button {
  margin-top: auto;
  margin-bottom: 20px;
  width: 100%;
  & > button {
    font-family: $font-medium;
    width: 100%;
    color: #fff;
    padding: 18px;
    border: 0;
    background-color: #000;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
  }
}
