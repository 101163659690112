@import "../../common/vars.scss";

.shedule {
  height: calc(100svh - 58px);
}

.wrapper {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.back {
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 13px;
  border-bottom: 1px solid #f1f2f3;

  & > button {
    background-color: transparent;
    border: 0;
  }
}

.title {
  font-family: $font-medium;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 0;
}

.description {
  color: #a7abac;
  font-family: $font-regular;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 40px;
}

.header {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #f1f2f3;

  &__info {
    display: flex;
    flex-direction: column;
    margin-left: 13px;

    &_name {
      font-family: $font-medium;
      font-size: 16px;
    }

    &_description {
      font-family: $font-regular;
      font-size: 12px;
      color: #a7abac;
    }
  }

  &__fullPrice {
    margin-left: auto;
    font-family: $font-medium;
  }
}

.schedule {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 16px;
  &__item {
    position: relative;
    &:first-of-type {
      font-family: $font-medium;
    }
    &::before {
      content: "";
      position: absolute;
      top: 43%;
      left: -2.5px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      border: 2px solid #a7abac;
      box-sizing: border-box;
    }
    &:first-of-type::before {
      content: "";
      position: absolute;
      top: 43%;
      left: -2.5px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #ef3124;
      border: 0;
    }
    & > span:first-of-type {
      margin-left: 16px;
    }
    display: flex;
    font-family: $font-regular;
    margin-bottom: 16px;
    justify-content: space-between;
  }
}

.schedule::before {
  content: "";
  position: absolute;
  top: 10px;
  width: 0;
  height: 79%;
  border-left: 1px dashed #e8e8e8;
}

.button {
  margin-top: auto;
  margin-bottom: 20px;
  & > button {
    font-family: $font-medium;
    width: 100%;
    color: #fff;
    padding: 18px;
    border: 0;
    background-color: #000;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
  }
}
