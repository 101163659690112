.wrapper {
    position: relative;
    overflow-x: hidden;
}

.back {
    position: absolute;
    top: 0;
    z-index: 2;
    padding-top: 13px;
    padding-left: 13px;

    & > button {
        background-color: transparent;
        border: 0;
    }
}