@import '../../common/vars.scss';

.slide {
    width: 100%;
    height: 240px;
    display: flex !important;
    align-items: center;
    justify-content: center;

    & > img {
        width: 390px;
    }
}

.wrapper {
    padding: 0px 20px;
    padding-bottom: 80px;
}

.title {
    font-family: $font-regular;
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.description {
    font-family: $font-regular;
    font-size: 16px;
}

.rating {
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-family: $font-regular;
    margin-bottom: 20px;

    &__amount {
        font-size: 12px;
        margin-left: 5px;
    }

    &__reviews {
        color: #A7ABAC;
        font-size: 12px;
        margin-left: 5px;
    }
}
.subtitle {
    font-weight: 500;
    font-family: $font-medium;
    font-size: 16px;
    margin-top: 20px;
}

.content {
    font-family: $font-regular;
    margin-top: 20px;
}

.table {
   display: flex;
   flex-direction: column;
   font-family: $font-regular;

   &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F1F2F3;
    padding-bottom: 10px;
    padding-top: 10px;

    &:last-of-type {
        border-bottom: 0;
    }

    & > span {
        width: 50%
    }
   }
}

.mt70 {
    margin-top: 70px;
  }

.offers {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 20px;
}

.offer {
    font-family: $font-regular;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    align-items: center;
    background-color: #F1F2F3;
    margin-bottom: 20px;

    button {
        font-family: $font-regular;
        background-color: #24252E;
        border-radius: 100px;
        color: #F5F5F5;
        outline: none;
        border: 0;
        padding: 5px 20px;
        align-self: flex-start;

        &:hover {
            opacity: .7;
        }
    }
  
    &__price {
        margin-left: 16px;
        margin-right: 60px;
        display: flex;
        flex-direction: column;
        
        &_split {
            font-size: 16px;
            font-family: $font-medium;
            margin-bottom: 5px;

            & > span {
                color: #7F7E82;
            }
        }

        &_full {
            font-size: 12px;
        }
    }
}

.best {
    background-color: rgba(56, 226, 63, 0.2);
}

.finalOffer {
    padding: 20px;
    background-color: #D7F9D9;
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;

    &__amount {
        display: flex;
        flex-direction: column;

        & > span {
            font-family: $font-medium;

            &:first-of-type {
                font-size: 16px;
                margin-bottom: 8px;
            }

            &:last-of-type {
                font-family: $font-regular;
                font-size: 12px;
            }
        
             & > span {
                color: #7F7E82;
             }
        }
    }

    button {
        font-family: $font-medium;
        background-color: #000000;
        padding: 16px 64px;
        margin-left: auto;
        border: 0;
        color: #fff;
        border-radius: 8px;
    }
}

:global {
    .slick-dots li button:before {
      font-size: 8px !important;
    }
    .slick-dots li {
        width: auto !important;
        margin: 0 !important;
    }

    .slick-slide {
        background: #f1f2f3;
    }
  }